import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        list: [],
        loader: false,
        refreshList: false,
        refreshLoader: false
    },
    mutations: {
        loadContentList(state,payload){
            state.refreshList = !state.refreshList;
            state.list = payload
        },
        loadLoaderList(state, payload){
            state.refreshLoader = !state.refreshLoader;
            state.loader = payload
        }
    },
    getters: {
        getterContentList(state){
            const dummy = state.refreshList; // eslint-disable-line no-unused-vars
            return state.list;
        },
        getterLoaderList(state){
            const dummy = state.refreshLoader; // eslint-disable-line no-unused-vars
            return state.loader;
        }
    },
    actions:{
        async getContentList(state){
            const timeout = setTimeout(function(){
                state.commit('loadLoaderList', false);
            }, 1000);

            await axios.get(
                API_URL + "list"
            ).then(
                response => {
                    if(response.status === 200){
                        clearTimeout(timeout);
                        state.commit('loadLoaderList', true);
                        state.commit('loadContentList', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async searchList(state,items){
            let requestLogin = API_URL + 'search/list';

            const timeout = setTimeout(function(){
                state.commit('loadLoaderList', false);
            }, 1000);

            let params = {};
            items.formData.forEach((item) => {
                let _key = item[0];
                if(_key !== 'value_label_search_state[all]'){
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    if(response.status === 200) {
                        clearTimeout(timeout);
                        state.commit('loadLoaderList', true);
                        state.commit('loadContentList', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
