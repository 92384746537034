<template>
    <div style="padding-bottom: 20px;">
        <GhNoDropSelector :datablock="{
            id: 'filter',
            title: $t('filter'),
            second_title: ''
        }">
            <template v-slot:content>
                <div v-if="haveData">
                    <component :is="component.filter" :key="'REFRESH_LIST_FILTER' + refresh"/>
                </div>
                <div v-else-if="!haveData">
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </div>
            </template>
        </GhNoDropSelector>

        <GhNoDropSelector :datablock="{
            id: 'list',
            title: $t('list'),
            second_title: ''
        }">
            <template v-slot:content>
                <div v-if="haveData && getterLoaderList">
                    <component :is="component.result"/>
                </div>
                <div v-else>
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </div>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import filter from './search/search.vue';
    import result from './result/result.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'view_list',
        components: {
            GhNoDropSelector
        },
        computed: {
            ...mapGetters(['getterLoaderList'])
        },
        data(){
            return {
                haveData: false,
                component: {
                    filter: filter,
                    result: result
                },
                refresh: 0
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedLevelCriticality', '', {root: true});
            await this.$store.dispatch('getFeedStates', '', {root: true});
            await this.$store.dispatch('getContentList','',{root:true});
            this.haveData = true;
        },
        methods: {
            async search(){
                const formData = new FormData(document.getElementById('form_search'));
                await this.$store.dispatch('searchList',{formData: [...formData]},{root:true});
            },
            async clean(){
                await this.$store.dispatch('getContentList','',{root:true});
                document.getElementById("form_search").reset();
                this.refresh++;
            },
        }
    }
</script>