<template>
    <div class="float_window">
        <form id="form_search">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <label class="gh_text_field-label T15_b" style="width: 150px; text-align: right; margin-right: 10px;">{{$t('criticity')}}</label>
                    <div v-for="(item, key) in getFeeds.level_criticity" :key="'INFRACTION' + key">
                        <GhChecks
                            v-bind:datainput="{
                                id: 'search_criticality_' + key,
                                value: key,
                                type: 'radio',
                                name: 'search[criticality]',
                                selected: false
                            }"
                            v-bind:datalabel="{
                                style: 'margin-right: 25px;',
                                class: 'T15 input_label',
                                id: 'label_search_criticality_' + key,
                                text: item
                            }"
                        />
                    </div>
                </div>
                <div class="gh-row-content">
                    <GhSimpleSearch
                        :datalabel="{
                            text: $t('code') + ' ' + $t('ee_ss'),
                            style: 'width: 150px; text-align:right;',
                            class: 'T15_b',
                            id: 'label_search_instalation_code'
                        }"
                        :datainput="{
                            required: false,
                            class: '',
                            id: 'value_search_instalation_code',
                            nameId: 'search[instalation_code]',
                            style: 'min-width: 100px; width: 200px',
                            value: '',
                            placeholder: '',
                            href: 'getInstalationCodeSearch'
                        }"
                    />
                    <GhDataSelector
                        id="historic_type_inspection"
                        :datablock="{
                            id: 'value_search_state',
                            class: 'T15_b',
                            subtitle: $t('select_state') + ':',
                            text: $t('state'),
                            label: $t('state'),
                            type: 'checkbox',
                            columns: 2,
                            style: '',
                            popupStyle: 'min-width: 35%;max-width: 35%;',
                            inputAll: true
                        }"
                        :datainput="{
                            id: 'value_label_search_state',
                            name: 'search[state][]'
                        }"
                        :feed="getFeeds.states"
                        :selected_input="[]"
                    />
                </div>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('main_company'),
                            id: 'label_main_company',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right;',
                        }"
                        :datainput="{
                            id: 'value_main_company',
                            name: 'search[main_company]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('afected_company'),
                            id: 'label_afected_company',
                            class: 'T15_b',
                            style: 'width: 149px; text-align:right; margin-left: 25px;',
                        }"
                        :datainput="{
                            id: 'value_afected_company',
                            name: 'search[afected_company]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_from',
                            text: $t('from'),
                            style: 'text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_date_from',
                            name: 'search[date_from]',
                            value: ''
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_until',
                            text: $t('until'),
                            style: 'width:150px; text-align: right; margin-left: 95px;'
                        }"
                        :datainput="{
                            id: 'input_search_end_date',
                            name: 'search[date_until]',
                            value: ''
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'search[criterio]',
                            selected: true
                        }"
                        v-bind:datalabel="{
                            style: '',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            style: 'margin-left:35px;',
                            name: 'search[criterio]',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('search')}" @click="$parent.$parent.search()"/>
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="$parent.$parent.clean()"/>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSimpleSearch from "fe-gh-simple-search-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: 'template_filter',
        components: {
            GhChecks,
            GhCalendar,
            GhSimpleSearch,
            GhDataSelector,
            GhInputTextField,
            GhButton
        },
        computed:{
            ...mapState(['getFeeds'])
        },
    }
</script>

<style>
    #value_search_state_selector_label {
        width: 175px;
        text-align: right;
    }
</style>