var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'PAGE' + _vm.refreshPage,staticStyle:{"max-width":"100%","margin-right":"auto","margin-left":"auto"}},[(_vm.$route.matched[0] !== undefined && _vm.$route.matched[0].props.default.showMenu)?[_c('GhMenu',{ref:"refMenu",scopedSlots:_vm._u([{key:"cabezera",fn:function(){return [_c('div',{staticClass:"logo_cabecera_square",staticStyle:{"margin-left":"8px"}},[_c('img',{staticStyle:{"max-width":"91px"},attrs:{"src":require('./assets/login/repsol.png'),"alt":""}})])]},proxy:true},{key:"header-platform_info",fn:function(){return [_c('GhUserIcon',{attrs:{"generalConfiguration":{
                        class: 'flex-row-textt',
                        icon: require('./assets/gh_new_dessign/user.svg'),
                        title: '',
                        style: 'top: 53px; right: 20px; position:fixed;',
                        id: 'Icon_User'
                    },"bodyConfiguration":{
                        class: 'flex-row-text',
                        title: '',
                        style: '',
                        id: 'Icon_User_Body'
                    }}}),_c('GhHelpIcon',{attrs:{"dataBlock":{icon: require('./assets/gh_new_dessign/help.svg')}}}),_c('GhLanguage',{attrs:{"dataBlock":{
                        title: _vm.$t('select_language'),
                        id:'select_lang',
                        icon: require('./assets/gh_new_dessign/arrowdown.svg')
                    },"language":_vm.languages,"selected":_vm.selectedLanguage}}),_c('div',{staticClass:"header-phone T4"},[_vm._v(" "+_vm._s('935 306 460')+" ")])]},proxy:true}],null,false,3030550317)}),_c('div',{staticStyle:{"margin-top":"93px"}}),_c('GhLocation',{key:_vm.refreshLocation,attrs:{"dataLocation":{
                showButton: false,
                secondWindow: false,
                showLoader: 'OK',
                text: _vm.$route.matched[0].props.default.otherTitle !== undefined ? _vm.$t(_vm.$route.matched[0].props.default.otherTitle) : _vm.$t(_vm.Tabs.TabActive)
            },"dataButton":{id: '', text: 'Boton', style: ''}}}),_c('div',{class:this.$route.path === '/' ? '' : 'content_page',attrs:{"id":"content_page"}},[_c('router-view'),_c('GhWarningTimeOut',{attrs:{"datablock":{
                    time: 1,
                }}}),_c(_vm.popups.popup_401,{tag:"component"})],1)]:[_c('router-view')],_c('GhDeleteConfirm',{attrs:{"visible_fields":_vm.Global.state.state_confirm.item_confirm,"id_popup":'delete_confirm',"content_warning":_vm.Global.state.state_confirm.subheader_confirm},on:{"function":_vm.setDelete}}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'warning_confirm',
            title: _vm.$t('required_fields'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: _vm.$t('must_be_follow_fields_complete') + ':'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px","padding-bottom":"15px"}},_vm._l((_vm.globalState.alert_required.requireds),function(item,index){return _c('div',{key:index,staticClass:"T15_b",staticStyle:{"padding-left":"20px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.Global.closePopUp('warning_confirm');}}})]},proxy:true}])}),(_vm.Global.state.window_open)?_c('div',{staticClass:"modal-backdrop"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }