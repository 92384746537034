import List from '@/view/list/list.vue';

const route = [
    {
        path: '/list',
        name: 'list',
        component: List,
        props: {showMenu: true},
    },
];

const routes = [
    ...route,
]

export default routes;