var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('form',{attrs:{"id":"form_search"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"right","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('criticity')))]),_vm._l((_vm.getFeeds.level_criticity),function(item,key){return _c('div',{key:'INFRACTION' + key},[_c('GhChecks',{attrs:{"datainput":{
                            id: 'search_criticality_' + key,
                            value: key,
                            type: 'radio',
                            name: 'search[criticality]',
                            selected: false
                        },"datalabel":{
                            style: 'margin-right: 25px;',
                            class: 'T15 input_label',
                            id: 'label_search_criticality_' + key,
                            text: item
                        }}})],1)})],2),_c('div',{staticClass:"gh-row-content"},[_c('GhSimpleSearch',{attrs:{"datalabel":{
                        text: _vm.$t('code') + ' ' + _vm.$t('ee_ss'),
                        style: 'width: 150px; text-align:right;',
                        class: 'T15_b',
                        id: 'label_search_instalation_code'
                    },"datainput":{
                        required: false,
                        class: '',
                        id: 'value_search_instalation_code',
                        nameId: 'search[instalation_code]',
                        style: 'min-width: 100px; width: 200px',
                        value: '',
                        placeholder: '',
                        href: 'getInstalationCodeSearch'
                    }}}),_c('GhDataSelector',{attrs:{"id":"historic_type_inspection","datablock":{
                        id: 'value_search_state',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_state') + ':',
                        text: _vm.$t('state'),
                        label: _vm.$t('state'),
                        type: 'checkbox',
                        columns: 2,
                        style: '',
                        popupStyle: 'min-width: 35%;max-width: 35%;',
                        inputAll: true
                    },"datainput":{
                        id: 'value_label_search_state',
                        name: 'search[state][]'
                    },"feed":_vm.getFeeds.states,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('main_company'),
                        id: 'label_main_company',
                        class: 'T15_b',
                        style: 'width: 150px; text-align:right;',
                    },"datainput":{
                        id: 'value_main_company',
                        name: 'search[main_company]',
                        type: 'text',
                        style: 'min-width: 100px; width: 196px'
                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('afected_company'),
                        id: 'label_afected_company',
                        class: 'T15_b',
                        style: 'width: 149px; text-align:right; margin-left: 25px;',
                    },"datainput":{
                        id: 'value_afected_company',
                        name: 'search[afected_company]',
                        type: 'text',
                        style: 'min-width: 100px; width: 196px'
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                        id: 'label_search_date_from',
                        text: _vm.$t('from'),
                        style: 'text-align: right;'
                    },"datainput":{
                        id: 'input_search_date_from',
                        name: 'search[date_from]',
                        value: ''
                    }}}),_c('GhCalendar',{attrs:{"datalabel":{
                        id: 'label_search_date_until',
                        text: _vm.$t('until'),
                        style: 'width:150px; text-align: right; margin-left: 95px;'
                    },"datainput":{
                        id: 'input_search_end_date',
                        name: 'search[date_until]',
                        value: ''
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datainput":{
                        id: 'radio_and',
                        value: 'and',
                        type: 'radio',
                        name: 'search[criterio]',
                        selected: true
                    },"datalabel":{
                        style: '',
                        class: 'T15 input_label',
                        id: 'label_and',
                        text: _vm.$t('all_search_criteria_entered')
                    }}}),_c('GhChecks',{attrs:{"datainput":{
                        id: 'radio_or',
                        value: 'or',
                        type: 'radio',
                        style: 'margin-left:35px;',
                        name: 'search[criterio]',
                    },"datalabel":{
                        style: 'margin-left: 4px;',
                        class: 'T15 input_label',
                        id: 'label_or',
                        text: _vm.$t('any_search_criteria_entered')
                    }}})],1)])]),_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_save', class: 'T19 container-md_button_content', text: _vm.$t('search')}},on:{"click":function($event){return _vm.$parent.$parent.search()}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_return',class: 'T19 container-md_button_content', text: _vm.$t('clean')}},on:{"click":function($event){return _vm.$parent.$parent.clean()}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }