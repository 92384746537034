<template>
    <GhTableCommon
        style="margin-top:10px;"
        :extratable="{
            id: 'results'
        }"
        :header="header"
        :data="getterContentList"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <template v-if="labelProp === 'action_download'">
                <GhAction
                    :dataction="{
                        id: 'download_' + labelProp + '_' + itemProp['id'],
                        text: $t('download'),
                        icon: require('@/assets/gh_new_dessign/descargar.svg')
                    }"
                    @click="downloadSanction(itemProp, 'inspections')"
                />
            </template>
            <template v-if="labelProp === 'action_view'">
                <GhAction
                    :dataction="{
                        id: 'view_' + labelProp + '_' + itemProp['id'],
                        text: $t('view'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }"
                    @click="setView(itemProp)"
                />
            </template>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'view_result',
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterContentList'])
        },
        data(){
            return {
                header: [
                    {text: 'date', field: 'date', sorting: true, typeSort: 'date', style: 'min-width: 90px; max-width: 90px; width: 90px;'},
                    {text: 'code_penalization', field: 'code_penalization', sorting: true, typeSort: 'string', style: 'min-width: 130px; max-width: 130px; width: 130px;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 130px; max-width: 130px; width: 19.90%;'},
                    {text: 'criticality', field: 'criticality', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 10%;'},
                    {text: 'code_eess', field: 'code_eess', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 10%;'},
                    {text: 'inspector', field: 'inspector', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'affected_company', field: 'affected_company', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'main_company', field: 'main_company', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'inspection', field: 'action_download', sorting: false, urlRedirect: true, style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'assent', field: 'action_view', sorting: false, urlRedirect: true, style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                ],
                haveData: false
            }
        },
        methods:{
            async downloadSanction(item, type){
                await this.$store.dispatch('downloadSanction',{
                    id: item.id_inspection,
                    type: type
                },{root:true});
            },
            async setView(item){
                console.log(item.slug,(item.slug === 'sanctions' || item.slug === 'qualified' || item.slug === 'shutdowns') ? 'view' : ((item.slug === 'qualify' || item.slug === 'resolve') ? item.slug : ''));
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: (item.slug === 'sanctions' || item.slug === 'qualified' || item.slug === 'shutdowns') ? 'view' : ((item.slug === 'qualify' || item.slug === 'resolve') ? item.slug : ''),
                    origin: item.slug,
                    id_company: item.id_inspection,
                    id: item.id
                }),'sanction');

                this.dispatcher = false;
            },
        }
    }
</script>
