import Vue from 'vue'
import Router from 'vue-router'

import Login from './login/route';
import Configuration from './configuration/route';
import ConfigurationPermissions from './configuration_permissions/route';
import NotAssociated from './not_associated/route';
import Sanction from './sanction/route';
import QualifiedInspections from './qualified_inspections/route';
import Shutdowns from './shutdowns/route';
import SanctionedCompanies from './sanctioned_companies/route';
import Historic from './historic/route';
import Shared from './shared/route';
import Search from './search/route';
import List from './list/route';


Vue.use(Router);

const routes = [
    ...Login,
    ...Configuration,
    ...ConfigurationPermissions,
    ...NotAssociated,
    ...Sanction,
    ...QualifiedInspections,
    ...Shutdowns,
    ...SanctionedCompanies,
    ...Historic,
    ...Shared,
    ...Search,
    ...List
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    GhTabs: [],
    routes
});

export default router;