var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'results'
    },"header":_vm.header,"data":_vm.getterContentList},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [(labelProp === 'action_download')?[_c('GhAction',{attrs:{"dataction":{
                    id: 'download_' + labelProp + '_' + itemProp['id'],
                    text: _vm.$t('download'),
                    icon: require('@/assets/gh_new_dessign/descargar.svg')
                }},on:{"click":function($event){return _vm.downloadSanction(itemProp, 'inspections')}}})]:_vm._e(),(labelProp === 'action_view')?[_c('GhAction',{attrs:{"dataction":{
                    id: 'view_' + labelProp + '_' + itemProp['id'],
                    text: _vm.$t('view'),
                    item_prop: itemProp,
                    icon: require('@/assets/gh_new_dessign/doc.svg')
                }},on:{"click":function($event){return _vm.setView(itemProp)}}})]:_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }