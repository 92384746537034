<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'table_incidences_pending_' + prop.id
            }"
            :header="header"
            :data="getterTablesSanction[prop.id] === undefined || getterTablesSanction[prop.id].list.length === 0 ? [] : getterTablesSanction[prop.id].list"
            :ref="'refIncidencesPending' + prop.id"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <label class="T15_b" :style="'color:' + itemProp['style_criticality'] + ';'">{{itemProp[labelProp]}}</label>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterTablesSanction[prop.id].actions.view">
                    <GhAction
                        :dataction="{
                            id: 'view_sanction_' + itemProp['id'],
                            text: $t('view'),
                            item_prop: itemProp,
                            icon: require('@/assets/gh_new_dessign/doc.svg')
                        }"
                        @click="setView(itemProp, 'view')"
                    />
                </template>
                <template v-if="itemProp.qualify && getterTablesSanction[prop.id].actions.qualify">
                    <GhAction
                        :dataction="{
                            id: 'edit_company_'+itemProp['id'],
                            text: $t('qualify'),
                            item_prop: itemProp,
                            icon: require('@/assets/gh_new_dessign/tick.svg')
                        }"
                        @click="setQualify(itemProp, 'qualify')"
                    />
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else class="centered" style="margin-top: 10px; margin-bottom: 10px;">
        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'sections_sanction',
        props: ['prop'],
        components:{
            GhTableCommon,
            GhAction
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'incidence_relative_to', field: 'incidence', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 70%;'},
                    {text: 'paralyzation', field: 'paralize', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'criticity', field: 'criticity', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false, style: 'min-width: 150px;'},
                ],
                timer: [],
                dispatcher: false,
            }
        },
        computed: {
            ...mapState(['Sanction']),
            ...mapGetters(['getterTablesSanction'])
        },
        mounted(){
            this.addEvent();
        },
        watch: {
            async '$parent.open'(data){
                if(data === true) {
                    await this.$store.dispatch('getPendingIncidencesCompany', {id: this.prop.id}, {root: true});

                    if (this.getterTablesSanction[this.prop.id] === undefined || this.getterTablesSanction[this.prop.id].actions === undefined || this.getterTablesSanction[this.prop.id].actions.qualify === undefined || !this.getterTablesSanction[this.prop.id].actions.qualify) {
                        delete this.header[5];

                        this.header = this.header.filter(function (item) {
                            return item !== undefined;
                        });
                    }

                    this.haveData = true;
                }else{
                    this.haveData = false;
                }
            },
            'haveData'(data){
                if(data){
                    this.addEvent();
                }else{
                    this.removeEvent();
                }
            }
        },
        methods: {
            addEvent(){
                let that = this;
                this.$nextTick(() => {
                    document.querySelectorAll('[id^="list_header_table_incidences_pending_' + that.prop.id + '"]').forEach((element) => {
                        element.addEventListener('click', that.clickHeader);
                    });

                    if(that.$parent.$parent.order['key' + that.prop.id] !== undefined && that.$parent.$parent.order['key' + that.prop.id].type === 'sorting-desc'){
                        if(that.$refs['refIncidencesPending' + that.prop.id] !== undefined) {
                            that.$refs['refIncidencesPending' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                        }
                    }

                    if(that.$parent.$parent.order['key' + that.prop.id] !== undefined && that.$parent.$parent.order['key' + that.prop.id].type === 'sorting-asc'){
                        if(that.$refs['refIncidencesPending' + that.prop.id] !== undefined) {
                            that.$refs['refIncidencesPending' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                            that.$refs['refIncidencesPending' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                        }
                    }
                });
            },
            removeEvent(){
                let that = this;
                document.querySelectorAll('[id^="list_header_table_incidences_pending_' + that.prop.id + '"]').forEach((element) => {
                    element.removeEventListener('click', that.clickHeader);
                });
            },
            async setView(item, type){
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: type,
                    origin: 'sanctions',
                    id_company: this.prop.id,
                    id: item.id
                }),'sanction');

                this.dispatcher = false;
            },
            async setQualify(item, type){
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: type,
                    id_company: this.prop.id,
                    id: item.id,
                    origin: type
                }),'sanction', false);

                this.dispatcher = true;
                this.timer = setInterval(await this.checkChildWindow, 500);

            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    if(this.dispatcher){
                        await this.$store.dispatch('getCountPendingIncidences', '', {root: true});
                        await this.$store.dispatch('getCompaniesPendingIncidences', '', {root: true});

                        this.$parent.$parent.refreshDrop++;
                    }
                    this.dispatcher = false;

                    this.Global.state.window_open = false;

                    clearInterval(this.timer);
                }
            },
            clickHeader(event){
                if(this.$parent.$parent.order['key' + this.prop.id] === undefined){
                    this.$parent.$parent.order['key' + this.prop.id] = [];
                    this.$parent.$parent.order['key' + this.prop.id].ho = '';
                    this.$parent.$parent.order['key' + this.prop.id].type = '';
                    this.$parent.$parent.order['key' + this.prop.id].cellIndex = '';
                }

                this.$parent.$parent.order['key' + this.prop.id].ho = event.target.id.split(this.prop.id + '_')[1];
                this.$parent.$parent.order['key' + this.prop.id].cellIndex = event.target.cellIndex;

                event.target.classList.forEach((i) => {
                    if(i === 'sorting-desc' || i === 'sorting-asc'){
                        this.$parent.$parent.order['key' + this.prop.id].type = i;
                    }
                });
            }
        }
    }
</script>
