var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"20px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
        id: 'filter',
        title: _vm.$t('filter'),
        second_title: ''
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('div',[_c(_vm.component.filter,{key:'REFRESH_LIST_FILTER' + _vm.refresh,tag:"component"})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()]},proxy:true}])}),_c('GhNoDropSelector',{attrs:{"datablock":{
        id: 'list',
        title: _vm.$t('list'),
        second_title: ''
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData && _vm.getterLoaderList)?_c('div',[_c(_vm.component.result,{tag:"component"})],1):_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }