import Vue from "vue";
import vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

//LOGIN
import Login from './login/Login';

//TABS
import Tabs from './tabs/tabs';

//FEEDS
import getFeeds from './feeds/feeds';

//CONFIGURATION
import Configuration from "./configuration/configuration";
import ConfigurationForms from "./configuration/forms/forms";
import ConfigurationCriticalities from "./configuration/criticalities/criticalities";
import ConfigurationActions from "./configuration/actions/actions";
import ConfigurationProcedures from "./configuration/procedures/procedures";

//CONFIGURATION PERMISSIONS
// --- GROUPS
import ListGroups from './configuration_permissions/groups/groups';

// --- RESULT SEARCH
import ListResults from './search/result/result';

//NOT ASSOCIATED
import NotAssociated from './not_associated/not_associated';
import NotAssociatedSection from './not_associated/not_associated/not_associated';

//SANCTION
import Sanction from "./sanction/sanction";

//QUALIFIED INSPECTIONS
import QualifiedInspections from "./qualified_inspections/qualified_inspections";
import QualifiedInspectionsSection from './qualified_inspections/qualified_inspections/qualified_inspections';
import ValidateAll from './qualified_inspections/qualified_inspections/window/validate_all';

//SHUTDOWNS
import Shutdowns from "@/store/shutdowns/shutdowns";
import ShutdownsSection from "@/store/shutdowns/shutdowns/shutdowns";

//SANCTIONED COMPANIES
import SanctionedCompanies from "@/store/sanctioned_companies/sanctioned_companies";
import SanctionedCompaniesSection from "@/store/sanctioned_companies/sanctioned_companies/sanctioned_companies";

//HISTORIC
import Historic from "./historic/historic";

//LIST
import List from "./list/list";

//SHAREDS
import SharedDetailSanctions from './shared/detail_sanctions/detail_sanctions';
import SharedPopupHistoric from './shared/popup_historic/popup_historic';

//EASYUPLOAD
import UploadFiles from './attach/upload';


Vue.use(vuex);

export default new vuex.Store({
    modules: {
        Login: Login,
        Tabs: Tabs,
        getFeeds: getFeeds,
        Configuration: Configuration,
        ConfigurationForms: ConfigurationForms,
        ConfigurationCriticalities: ConfigurationCriticalities,
        ConfigurationActions: ConfigurationActions,
        ConfigurationProcedures: ConfigurationProcedures,
        NotAssociated: NotAssociated,
        NotAssociatedSection: NotAssociatedSection,
        Sanction: Sanction,
        QualifiedInspections: QualifiedInspections,
        QualifiedInspectionsSection: QualifiedInspectionsSection,
        ValidateAll: ValidateAll,
        Shutdowns: Shutdowns,
        ShutdownsSection: ShutdownsSection,
        SanctionedCompanies: SanctionedCompanies,
        SanctionedCompaniesSection: SanctionedCompaniesSection,
        Historic: Historic,
        List: List,
        SharedDetailSanctions: SharedDetailSanctions,
        SharedPopupHistoric: SharedPopupHistoric,
        ListGroups: ListGroups,
        ListResults: ListResults,
        UploadFiles: UploadFiles
    },
    plugins: [createPersistedState()]
});

